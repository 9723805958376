<script lang="ts" setup>
const emit = defineEmits<{
  done: []
  notifyChildAppTracker: []
}>()

const isModalOpen = defineModel<boolean>({ required: true })

const { t } = useI18n()
const { mutateAsync: sendContactRequest, isPending, status } = useMutationIntercom.sendContactRequest()
const { trackSubmitContactForm } = useTracker()
const { success } = useToast()
const { notify } = useBugsnag()

const formId = computed(() => `${getCurrentInstance()?.uid}-contact-us-form`)

const message = ref('')

const close = () => {
  isModalOpen.value = false
}

// reset message when modal is opened
watchEffect(() => {
  if (isModalOpen.value)
    message.value = ''
})

const submit = async () => {
  try {
    await sendContactRequest({
      payload: {
        message: message.value,
      },
    })

    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.get('is-child-app') !== 'true')
      trackSubmitContactForm()
    else
      emit('notifyChildAppTracker')

    emit('done')
    close()
    success(t('contact-us-modal.success-message'))
  }
  catch (error) {
    if (error instanceof Error)
      notify(error)
  }
}

defineExpose({
  open,
})
</script>

<template>
  <DTModal
    v-model="isModalOpen"
    @close="close"
  >
    <DTModalHeader :title="$t('contact-us-modal.title')" />
    <DTModalBody class="space-y-6 !py-6">
      <ErrorAlert
        v-if="status === 'error'"
        :error="$t('contact-us-modal.error')"
      />
      <form
        :id="formId"
        class="grid grid-cols-2 gap-3"
        @submit.prevent="submit"
      >
        <DTField
          :label="$t('message')"
          class="col-span-full"
        >
          <DTFieldTextarea
            v-model="message"
            required
            rows="5"
          />
        </DTField>
      </form>
    </DTModalBody>
    <DTModalFooter>
      <DTButton
        :disabled="isPending"
        @click="close"
      >
        {{ $t('cancel') }}
      </DTButton>
      <DTButton
        variant="blue"
        type="submit"
        :form="formId"
        :disabled="isPending || !message.trim().length"
      >
        {{ $t('submit') }}
        <DTSpinner
          v-if="isPending"
          variant="white"
          :size="4"
          :weight="2"
        />
      </DTButton>
    </DTModalFooter>
  </DTModal>
</template>
